module.exports = (() => {
    function waitForWindowValue (prop) {
        return new Promise((resolve) => {
            if (window[prop]) {
                return resolve(window[prop]);
            }
            let value;
            Object.defineProperty(window, prop, {
                get: () => value,
                set: (newValue) => {
                    resolve(value = newValue);
                },
            });
        });
    }

    const origin = new URL(document.currentScript && document.currentScript.src).origin;
    const url = origin + "/assets/_env.js";
    const scriptElement = document.createElement("script");
    const valuePromise = waitForWindowValue(".petasosEnv");

    scriptElement.src = url;
    document.head.append(scriptElement);
    valuePromise.finally(() => scriptElement.remove());

    return valuePromise;
})();;